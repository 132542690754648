<div class="d-flex justify-content-between">
  <h2 *ngIf="!this.enableGIF" mat-dialog-title class="sticky">Feedback</h2>
  <div></div>
  <i *ngIf="this.enableGIF" mat-dialog-close id="times-close-icon"
    style="float: right; font-size: 16px; cursor: pointer" class="fa fa-times"></i>
</div>

<form *ngIf="this.enableGIF==false" [formGroup]="myForm" p="3">
  <div class="col-md-12">
    <mat-label>1. How easy are the features to use? *</mat-label>
    <div class="ml-1 mt-2">
      <mat-radio-group formControlName="radioOption">
        <mat-radio-button class="ml-2" *ngFor="let level of levels" [value]="level">{{level}}</mat-radio-button>
      </mat-radio-group>
    </div>


    <div class="mt-2">
      <mat-label>2. What are the main objectives you want to achieve with
        PredictSense? * </mat-label>
      <mat-form-field class="ml-2" appearance="outline">
        <textarea matInput formControlName="objectives" style="max-height: 100px;"></textarea>
        <mat-error
          *ngIf=" myForm.controls['objectives'].hasError('required') && myForm.controls['objectives'].touched">Please
          provide objectives.</mat-error> </mat-form-field>
    </div>

    <div class="mt-2">
      <mat-label>3. Which features do you use most often? *</mat-label>
      <div class="ml-2 mt-2" *ngFor="let feature of features; let i= index">
        <mat-checkbox class="ml-2" formControlName="checkboxOption" (change)="onCheckboxChange($event,i)"
          [value]="feature" required>{{feature}}</mat-checkbox>
      </div>
      <mat-error *ngIf=" myForm.controls['checkboxOption'].hasError('required') && checkboxValidation">Please
        check feature(s) you often use.</mat-error>

    </div>
    <div class="mt-2"> <mat-label>4. Do you have any suggestions for us? *</mat-label> <mat-form-field class="ml-2"
        appearance="outline">
        <textarea matInput formControlName="suggestions" style="max-height: 100px;"></textarea> <mat-error
          *ngIf=" myForm.controls['suggestions'].hasError('required') && myForm.controls['suggestions'].touched">Please
          provide suggestions.</mat-error> </mat-form-field> </div>

  </div>
  <div class="row justify-content-between">
    <div class="col-md-6 ml-4"> <button mat-stroked-button mat-dialog-close> Cancel </button> </div>
    <div class="mr-4"> <button (click)="onSubmit()"
        [disabled]="myForm.get('radioOption').value === '' || myForm.get('objectives').value === '' || myForm.get('checkboxOption').value === '' || this.checkboxValidation == true || myForm.get('suggestions').value === ''"
        mat-flat-button class="btn-primary" type="submit"> Submit </button> </div>

  </div>

</form>

<div *ngIf="this.enableGIF" class='row' style='display: flex; flex-direction: column'>
  <div style='text-align:center' class='col-md-12'><img src='../../../../assets/img/congratulations.gif'
      width='50%' />
  </div>
  <div style='margin: auto' class='col-md-12'>
    <h3 *ngIf="!surveyResponseList.length" class='font-weight-lighter mt-5' style='text-align:center '>Thank you for completing
      the survey!<br>Your account has been credited with {{feedbackProjectRewardCount}} extra projects.</h3>
  </div>
  <div>
    <h3 *ngIf="surveyResponseList.length" style='text-align:center'>Thank you for completing the survey!</h3>
  </div>
</div>