import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeedbackFormService } from 'src/app/layout/feedback-form/feedback-form.service';
import { LocalStorageService } from 'src/app/shared/local-storage.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  myForm: FormGroup;
  levels: string[] = ['Very Hard', 'Hard', 'Neutral', 'Easy', 'Very Easy'];
  // features: string[] = ['EDA', 'Training', 'Tuning', 'Prediction'];
  checkedFeatureValue: any;
  features: string[] = ['EDA', 'Training', 'Tuning', 'Prediction'];
  enableGIF: boolean = false
  unsubscribe$ = new Subject<boolean>();
  private _toaster: any;
  private _spinner: any;
  isChecked = false;
  selectedItems: string[] = [];
  checkboxValidation: boolean;
  user: any;
  surveyType: string;
  projectRewarded: boolean = true;
  surveyResponseList: Array<any> = [];
  surveySubmited: boolean=false;
  feedbackProjectRewardCount: any;
  subscriptionId: any;

  constructor(
    private fb: FormBuilder,
    private _feedback: FeedbackFormService,
    private _localStorage: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: { surveyType: string },
  ) {

    this.user = this._localStorage.getItem('user');
    this.surveyType = this.data.surveyType;
    this.feedbackProjectRewardCount = this._localStorage.getItem('user')?.psFeatures?.feedbackProjectRewardCount || 2;
    this.user.id = this._localStorage.getItem('user')._id;
    this.subscriptionId = this._localStorage.getItem('user').subscription;
    this.myForm = this.fb.group({
      radioOption: ['', [Validators.required]],
      objectives: ['', [Validators.required]],
      checkboxOption: ['', [Validators.required]],
      suggestions: ['', [Validators.required]],
    });

  }

  ngOnInit(): void {
    this.getSurveys();
    this.checkRewardStatus()

  }
  onCheckboxChange(event: any, item: string) {
    if (event.checked) {
      this.selectedItems.push(event.source.value);
    } else {
      const index = this.selectedItems.indexOf(event.source.value);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);

      }
    }
    if (this.selectedItems.length == 0) {
      this.checkboxValidation = true;
    }
    else {
      this.checkboxValidation = false;
    }
  }

  onSubmit() {
    var feedback = {
      radioOption: this.myForm.controls['radioOption'].value,
      objectives: this.myForm.controls['objectives'].value,
      checkboxOption: this.selectedItems,
      suggestions: this.myForm.controls['suggestions'].value,
      surveyType: 'rewardSurvey',
      subscriptionId: this.user.subscription,
    };
    this._feedback.createFeedback(feedback).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.enableGIF = true;
    }, (err: HttpErrorResponse) => {
      this._toaster.error(err || 'Something went wrong');
      this._spinner.stopSpinner();

    })

  }
  /**
 * Fetches all the surveys
 */
  getSurveys(): void {
    // API call
    this._feedback
      .getSurveyList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.length) {
            this.surveyResponseList = res;
            this.surveyResponseList = this.surveyResponseList.filter(data => {
              return data.subscriptionId == this.user.subscription
              
            }); 
          }
          (err: HttpErrorResponse) => { }

        })


  }
  checkRewardStatus = () => {
    let surveySubmited = false;
    this.surveyResponseList.forEach((response) => {
      if (response.createdBy === this.user && response.surveyType === 'rewardSurvey') {
        if (response.subscriptionId === this.subscriptionId && response.surveyType === 'rewardSurvey') {
          surveySubmited = true;
        }
        }
      });
  
  };
}
