import { LocalStorageService } from './../../shared/local-storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private _router: Router, private _localStorage: LocalStorageService) {}

  /**
   * Activates the child routes or redirect it, based on certain conditions
   * @param childRoute
   * @param state
   * @returns
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const user: any = this._localStorage.getItem('user');
    if (user !== null) {
      if (user.status === 'active' || user.isFreeTrial || user.subscription) {
        if (state.url === '/login') {
          this._router.navigate(['/dashboard']);
          return false;
        } else {
          return true;
        }
      } else {
        this._router.navigate(['/dashboard/plans']);
        return false;
      }
    } else {
      return true;
    }
  }

  /**
   * Activates the route only if user is logged in, if not redirect to login page
   * @param route
   * @param state
   * @returns
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user: any = this._localStorage.getItem('user');
    if (user !== null) {
      return true;
    } else {
      this._router.navigate(['/login']);
      return false;
    }
  }
}
